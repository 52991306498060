var render = function render(){var _vm=this,_c=_vm._self._c;return _c('quest-main',{attrs:{"title":"Properties Management","copy":"Manage Quest properties by selecting from the table below","loading":_vm.loading},scopedSlots:_vm._u([{key:"head",fn:function(){return [(_vm.currentAccount?.service === _vm.ExternalServiceType.TBB)?_c('router-link',{staticClass:"d-sm-inline-block btn btn-sm btn-primary shadow-sm",attrs:{"to":{ name: 'properties-add' }}},[_c('font-awesome-icon',{staticClass:"text-white-50",attrs:{"icon":"plus","size":"sm"}}),_vm._v(" Add")],1):_vm._e(),(
      _vm.currentAccount?.service === _vm.ExternalServiceType.RMS
    )?_c('button',{staticClass:"d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2",on:{"click":function($event){return _vm.addRMS()}}},[_c('font-awesome-icon',{staticClass:"text-white-50",attrs:{"icon":"plus","size":"sm","fixed-width":""}}),_vm._v(" Add ")],1):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('quest-card',{attrs:{"title":"Filters","id":"filterCard","collapsable":true,"collapsed":true}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('quest-input',{attrs:{"label":"Search","id":"search","help":"Filter properties by their name"},on:{"enter":function($event){return _vm.filter()}},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1),_c('div',{staticClass:"col-md-6"},[_c('quest-single-select',{attrs:{"options":_vm.countries,"label":"Country","id":"country","placeholder":"All","help":"Filter properties by country","keepPlaceholder":true},on:{"update":_vm.updateCountry},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)]),_c('div',{staticClass:"justify-content-end d-flex gap-2"},[_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.clear()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times'],"fixed-width":""}}),_vm._v(" Clear ")],1),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.filter()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'filter'],"fixed-width":""}}),_vm._v(" Filter ")],1)])]),_c('quest-card',{attrs:{"title":"Properties"}},[_c('quest-table',{attrs:{"id":"properties-table","columns":_vm.columns}},[(_vm.properties.items.length > 0)?_vm._l((_vm.properties.items),function(property){return _c('tr',{key:property.id,staticClass:"small align-middle"},[_c('td',[_c('router-link',{attrs:{"to":{
                name: 'properties-edit',
                params: { id: property.id },
              }}},[_vm._v(_vm._s(property.name))]),_c('br'),_c('router-link',{staticClass:"small",attrs:{"to":{
                name: 'properties-edit',
                hash: '#rooms',
                params: { id: property.id },
              }}},[_vm._v("(Rooms)")])],1),_c('td',[_vm._v(_vm._s(property.channelCode))]),_c('td',[_vm._v(_vm._s(property.state))]),_c('td',[_vm._v(_vm._s(property.country))]),_c('td',[(property.isEnabled)?_c('span',{staticClass:"text-success"},[_vm._v("Enabled")]):_vm._e(),(!property.isEnabled && property.isGhost)?_c('span',{staticClass:"text-warning"},[_vm._v("Ghost")]):_vm._e(),(!property.isEnabled && !property.isGhost)?_c('span',{staticClass:"text-danger"},[_vm._v("Disabled")]):_vm._e()]),_c('td',[_vm._v(_vm._s(property.modified))]),_c('td',[_c('b-dropdown',{attrs:{"no-caret":"","variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":"ellipsis-v","size":"sm","fixed-width":""}})]},proxy:true}],null,true)},[_c('b-dropdown-header',[_vm._v("Actions")]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'properties-edit',
                  params: { id: property.id },
                }}},[_vm._v("View")]),(_vm.currentAccount?.service === _vm.ExternalServiceType.TBB)?[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.purgeQuotes(property)}}},[_vm._v("Purge Quotes")]),_c('b-dropdown-divider'),_c('b-dropdown-item-button',{attrs:{"text":"danger","variant":"danger"},on:{"click":function($event){return _vm.promptDelete(property)}}},[_vm._v("Delete")])]:_vm._e()],2)],1)])}):_vm._e(),(_vm.properties.items.length === 0)?[_c('tr',{staticClass:"align-middle"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"small text-center py-5"},[_vm._v(" No properties were found ")])])])]:_vm._e()],2),(_vm.properties.total > 12)?_c('quest-pager',{attrs:{"currentPage":_vm.page,"itemCount":_vm.properties.total,"pageSize":_vm.pageSize},on:{"changePage":_vm.changePage}}):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","title":"Delete"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"button"},on:{"click":_vm.cancelPromptDelete}},[_vm._v(" Cancel")]),_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":_vm.confirmPromptDelete}},[_vm._v(" Delete ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.toDelete)?_c('p',{staticClass:"my-4"},[_vm._v(" Are you sure you wish to delete property '"+_vm._s(_vm.toDelete.name)+"'? ")]):_vm._e()]),_c('b-modal',{attrs:{"centered":"","title":'Add new RMS property'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"button"},on:{"click":_vm.cancelRMS}},[_vm._v(" Cancel")]),_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"disabled":!_vm.clientIdForRMS ||
            !_vm.externalIdForRMS,"type":"button"},on:{"click":_vm.confirmRMS}},[_vm._v(" Confirm ")])]},proxy:true}]),model:{value:(_vm.showRMSModal),callback:function ($$v) {_vm.showRMSModal=$$v},expression:"showRMSModal"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('quest-input',{attrs:{"id":"clientIdForRMS","label":"RMS Client No.","help":"Set the RMS Client No."},model:{value:(_vm.clientIdForRMS),callback:function ($$v) {_vm.clientIdForRMS=$$v},expression:"clientIdForRMS"}}),_c('quest-input',{attrs:{"id":"externalIdForRMS","label":"Property Id","help":"Set the Id of the property"},model:{value:(_vm.externalIdForRMS),callback:function ($$v) {_vm.externalIdForRMS=$$v},expression:"externalIdForRMS"}})],1)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }